import type React from 'react';

import { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import gsap from 'gsap';

import LevelRange from './components/Ranges/LevelRange';
import AreaRange from './components/Ranges/AreaRange';
import BedsRange from './components/Ranges/BedsRange';
import BathsRange from './components/Ranges/BathsRange';
import CloseIcon from '../../components/CloseIcon';
import Button from '../../components/Button';

import globalStyles from '../../data/globalStyles';
import type { IFiltersInitialState } from '../../data/initialState';
import type { TUnit } from '../../lib/formatData';
import { useStore } from '../../../../rexall-controller/src/state/store';

interface IFilterProps
  extends Omit<
    IFiltersInitialState,
    'minLevel' | 'maxLevel' | 'minArea' | 'maxArea' | 'filteredUnits'
  > {
  orbitIndex: number;
  onFilteredChangedEffect: (filtered: TUnit[]) => void;
  onResetClicked: () => void;
  onCloseClicked: () => void;
  onDestroy: () => void;
}

function Filter({
  fullUnits,
  filterOpen,
  beds,
  baths,
  levelRange,
  areaRange,
  faces,
  orbitIndex,
  onFilteredChangedEffect,
  onResetClicked,
  onCloseClicked,
  onDestroy,
  ...props
}: IFilterProps & React.HTMLAttributes<HTMLDivElement>) {
  const filterRef = useRef<HTMLDivElement>(null);
  // Get min/max values from the store
  const { minLevel, maxLevel, minArea, maxArea } = useStore((s) => ({
    minLevel: s.minLevel,
    maxLevel: s.maxLevel,
    minArea: s.minArea,
    maxArea: s.maxArea,
  }));

  // Add state to track temporary filter values
  const [tempBeds, setTempBeds] = useState(beds);
  const [tempBaths, setTempBaths] = useState(baths);
  const [tempLevelRange, setTempLevelRange] = useState(levelRange);
  const [tempAreaRange, setTempAreaRange] = useState(areaRange);
  const [tempFaces, setTempFaces] = useState(faces);

  // Add loading states for buttons
  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);

  // Update temp values when props change
  useEffect(() => {
    setTempBeds(beds);
    setTempBaths(baths);
    setTempLevelRange(levelRange);
    setTempAreaRange(areaRange);
    setTempFaces(faces);
  }, [beds, baths, levelRange, areaRange, faces]);

  useEffect(() => {
    if (filterRef.current) {
      gsap.to(filterRef.current, {
        opacity: filterOpen ? 1 : 0,
        pointerEvents: filterOpen ? 'all' : 'none',
        onStart: () => {
          filterOpen &&
            gsap.set(filterRef.current, {
              display: 'initial',
            });
        },
        onComplete: () => {
          !filterOpen &&
            gsap.set(filterRef.current, {
              display: 'none',
            });
        },
      });
    }
  }, [filterOpen]);

  // Apply filters function with loading state
  const applyFilters = () => {
    if (fullUnits && !isApplyLoading) {
      setIsApplyLoading(true);

      // Simulate a delay for better UX
      setTimeout(() => {
        let filtered: TUnit[] = [];
        filtered = fullUnits.filter((obj) => {
          return (
            obj.level >= (tempLevelRange[0] || 0) &&
            obj.level <= (tempLevelRange[1] || 0) &&
            obj.area >= (tempAreaRange[0] || 0) &&
            obj.area <= (tempAreaRange[1] || 0) &&
            (!tempFaces.length ||
              tempFaces.some((item: any) => obj.aspect.includes(item))) &&
            (!tempBeds.length || tempBeds.includes(obj.beds)) &&
            (!tempBaths.length || tempBaths.includes(obj.baths))
          );
        });
        onFilteredChangedEffect(filtered);
        setIsApplyLoading(false);
      }, 800); // 800ms delay for the loading effect
    }
  };

  // Reset function with loading state
  const handleReset = () => {
    if (!isResetLoading) {
      setIsResetLoading(true);

      // Simulate a delay for better UX
      setTimeout(() => {
        onResetClicked();

        // Apply the reset filters immediately
        if (fullUnits) {
          const resetFilters: {
            beds: number[];
            baths: number[];
            faces: any[];
            levelRange: [number, number];
            areaRange: [number, number];
          } = {
            beds: [],
            baths: [],
            faces: [],
            levelRange: [minLevel ?? 0, maxLevel ?? 0],
            areaRange: [minArea ?? 0, maxArea ?? 0],
          };

          // Update our temporary state
          setTempBeds(resetFilters.beds);
          setTempBaths(resetFilters.baths);
          setTempLevelRange(resetFilters.levelRange);
          setTempAreaRange(resetFilters.areaRange);
          setTempFaces(resetFilters.faces);

          // Filter with reset values
          const filtered = fullUnits.filter((obj) => {
            return (
              obj.level >= (resetFilters.levelRange[0] || 0) &&
              obj.level <= (resetFilters.levelRange[1] || 0) &&
              obj.area >= (resetFilters.areaRange[0] || 0) &&
              obj.area <= (resetFilters.areaRange[1] || 0) &&
              (!resetFilters.faces.length ||
                resetFilters.faces.some((item: any) =>
                  obj.aspect.includes(item),
                )) &&
              (!resetFilters.beds.length ||
                resetFilters.beds.includes(obj.beds)) &&
              (!resetFilters.baths.length ||
                resetFilters.baths.includes(obj.baths))
            );
          });

          // Apply the filtered results
          onFilteredChangedEffect(filtered);
        }

        setIsResetLoading(false);
      }, 800); // 800ms delay for the loading effect
    }
  };

  useEffect(() => {
    return () => {
      onDestroy();
    };
  }, []);

  return (
    <FilterWrapper {...props} ref={filterRef}>
      <FilterColumn>
        <FilterCategory>
          <FilterLabel>BEDS</FilterLabel>
          <BedsRange beds={tempBeds} onChange={setTempBeds} />
        </FilterCategory>
        <FilterCategory>
          <FilterLabel>BATHS</FilterLabel>
          <BathsRange baths={tempBaths} onChange={setTempBaths} />
        </FilterCategory>
        <FilterCategory>
          <FilterLabel>FLOOR</FilterLabel>
          <LevelRange
            levelRange={tempLevelRange}
            onChange={setTempLevelRange}
          />
        </FilterCategory>
        <FilterCategory>
          <FilterLabel>INTERNAL AREA</FilterLabel>
          <AreaRange areaRange={tempAreaRange} onChange={setTempAreaRange} />
        </FilterCategory>
        <ButtonContainer>
          <ButtonWrapper>
            <Button
              label={isResetLoading ? '' : 'Reset'}
              active={!isResetLoading}
              style={{
                width: '100%',
                minWidth: '100%',
                fontSize: '0.8rem',
                position: 'relative',
                opacity: isResetLoading ? 0.8 : 1,
              }}
              onClick={handleReset}
            />
            {isResetLoading && <Spinner />}
          </ButtonWrapper>

          <ButtonWrapper>
            <Button
              label={isApplyLoading ? '' : 'Apply'}
              active={!isApplyLoading}
              style={{
                width: '100%',
                minWidth: '100%',
                fontSize: '0.8rem',
                backgroundColor: globalStyles.colors.black,
                color: globalStyles.colors.white,
                position: 'relative',
                opacity: isApplyLoading ? 0.8 : 1,
              }}
              onClick={applyFilters}
            />
            {isApplyLoading && <Spinner color="#fff" />}
          </ButtonWrapper>
        </ButtonContainer>
      </FilterColumn>
      <CloseIcon
        className="closeIcon"
        bgColor={globalStyles.colors.black}
        strokeColor={globalStyles.colors.white}
        strokeWidth={2}
        onClick={() => {
          onCloseClicked();
        }}
      />
    </FilterWrapper>
  );
}

export default Filter;

const FilterWrapper = styled.div`
  position: absolute;
  z-index: 5;
  width: clamp(500px, 40%, 700px);
  height: 72%;
  background-color: ${globalStyles.colors.white};
  opacity: 0;
  pointer-events: none;
  left: ${globalStyles.margin};
  bottom: calc(8% + ${globalStyles.margin});
  border-radius: ${globalStyles.borderRadius};

  .closeIcon {
    position: absolute;
    width: 4rem;
    height: 4rem;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    pointer-events: all;
    cursor: pointer;
  }
`;

const FilterColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

const FilterCategory = styled.div`
  padding: ${globalStyles.margin};
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${globalStyles.colors.black}1a;
`;

const FilterLabel = styled.p`
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  color: ${globalStyles.colors.black};
`;

const ButtonContainer = styled.div`
  padding: ${globalStyles.margin};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  position: relative;
  width: 48%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const spin = keyframes`
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
`;

const Spinner = styled.div<{ color?: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top-color: ${(props) => props.color || globalStyles.colors.black};
  border-radius: 50%;
  animation: ${spin} 0.8s linear infinite;
  pointer-events: none;
`;

import styled from 'styled-components';

import MenuButton from 'common/src/web_components/MenuButton';
import Button from 'common/src/components/Button';
import FinishesComposition from 'common/src/components/FinishesComposition';
import FullWidthLiving from 'common/src/components/FullWidthLiving';

import globalStyles from 'common/src/data/globalStyles';
import { useStore } from '@state/store';
import specCopy from 'common/src/data/specCopy';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';

function Details() {
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const { palette, specDisplays } = useStore((s) => ({
    palette: s.palette,
    specDisplays: s.specDisplays,
  }));
  const finishes = ['Light', 'Medium', 'Dark'];
  const display = ['copy', 'image'];

  // if (!import.meta.env.VITE_IS_WEB_BUILD) {
  //   display.push('clear');
  // }

  return (
    <DetailsWrapper>
      <MenuButton
        onMenuClicked={() => {
          syncMultipleStatesWithSocket({
            menuOpen: true,
            menuIndex: null,
          });
        }}
      />
      <Content>
        <FullWidthLiving finishState={palette} className="living" />
        <CompositionRender
          style={{
            opacity: specDisplays === 'image' ? 1 : 0,
          }}
        >
          <FinishesComposition finishState={palette} className="compositions" />
        </CompositionRender>
        <Copy
          style={{
            opacity:
              specDisplays === 'copy' || specDisplays === 'clear' ? 1 : 0,
          }}
        >
          {specCopy.map((obj, i) => {
            return obj.list ? (
              <div key={i} className="listWrapper">
                <h3 className="listTitle">{obj.title}</h3>
                <ul className="list">
                  {obj.body.map((line, i) => {
                    return <li key={i}>{line}</li>;
                  })}
                </ul>
              </div>
            ) : (
              <div key={i} className="titleWrapper">
                <h1 className="title">{obj.title}</h1>
                <p className="copy">{obj.body[0]}</p>
              </div>
            );
          })}
        </Copy>
      </Content>
      <Finishes>
        {finishes.map((label, i) => {
          return (
            <Button
              key={`specBtn${i}`}
              label={label}
              active={i === palette}
              onClick={() => {
                syncMultipleStatesWithSocket({
                  palette: i,
                });
              }}
            />
          );
        })}
      </Finishes>
      {/* <ContentSwitcher>
        {display.map((label, i) => {
          return (
            <Button
              key={`specDisplayBtn${i}`}
              label={label === 'copy' ? 'details' : label}
              active={label === specDisplays}
              onClick={() => {
                syncMultipleStatesWithSocket({
                  specDisplays: label as any,
                });
              }}
            />
          );
        })}
      </ContentSwitcher> */}
    </DetailsWrapper>
  );
}

export default Details;

const DetailsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  --gridSize: 90vh;

  .gridGalleryWrapper {
    top: calc((100% - var(--gridSize)) / 2);
    left: calc((100% - var(--gridSize)) / 2);
    width: var(--gridSize);
    height: var(--gridSize);
  }
`;

const Finishes = styled.div`
  position: absolute;
  bottom: ${globalStyles.margin};
  left: ${globalStyles.margin};
  display: flex;
  gap: ${globalStyles.margin};
`;

const ContentSwitcher = styled.div`
  position: absolute;
  bottom: ${globalStyles.margin};
  right: ${globalStyles.margin};
  display: flex;
  gap: ${globalStyles.margin};
`;

const Content = styled.div`
  position: absolute;
  width: calc(100% - (${globalStyles.margin} + ${globalStyles.margin}));
  height: 85%;
  left: ${globalStyles.margin};
  top: 5%;

  > * {
    transition: opacity 0.8s ease-in-out;
  }

  .living {
    position: absolute;
    right: 0;
    width: calc(50% - ${globalStyles.margin});
    border-radius: ${globalStyles.borderRadius};
    background-size: cover;
    background-position: center;

    & img {
      object-fit: cover;
      border-radius: ${globalStyles.borderRadius};
    }
  }

  .compositions {
    border-radius: ${globalStyles.borderRadius};
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;

    & img {
      object-fit: contain;
      width: 100%;
    }
  }
`;

const Copy = styled.div`
  position: absolute;
  width: calc(53% - ${globalStyles.margin});
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  flex-wrap: wrap;
  font-size: clamp(6px, 1rem, 20px);
  gap: clamp(8px, 0.8rem, 20px);
  text-align: left;

  .titleWrapper {
    font-weight: 100;
    flex: 1 1 100%;
    margin-bottom: ${globalStyles.margin};
  }

  .title {
    color: ${globalStyles.colors.second};
    margin-bottom: ${globalStyles.margin};
    font-family: title;
    font-weight: 100;
  }

  .copy {
    color: ${globalStyles.colors.white};
    word-spacing: 0.1rem;
    font-family: main;
  }

  .listWrapper {
    flex: 1 1 40%;
  }

  .listTitle {
    color: ${globalStyles.colors.second};
    font-family: title;
    font-weight: 100;
    letter-spacing: 0.1rem;
  }

  .list {
    padding-inline-start: 0;
    padding-left: 1em;

    & li {
      font-family: main;
      color: ${globalStyles.colors.white};
      margin: 1rem 0;
      ::marker {
        color: ${globalStyles.colors.second};
      }
    }
  }
`;

const CompositionRender = styled.div`
  position: absolute;
  width: calc(50% - ${globalStyles.margin});
  height: 60%;
  top: 20%;
  left: 0;
`;

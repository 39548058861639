import { act, useEffect, useState } from 'react';
import styled from 'styled-components';

import ImmersionMapControl from 'common/src/web_components/MapSections/ImmersionMapControl';
import CloseIcon from 'common/src/components/CloseIcon';
import ListButtonIcon from 'common/src/components/SVGIcons/ListButtonIcon';

import globalStyles from 'common/src/data/globalStyles';
import mapData from 'common/src/data/mapData';
import { useStore } from '@state/store';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import MenuButton from 'common/src/web_components/MenuButton';
import Button from 'common/src/components/Button';

function Maps() {
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();
  const [sectionsOpen, setSectionsOpen] = useState(false);
  const { activeCategory, activePOI, showPoiImage } = useStore((s) => ({
    activeCategory: s.activeCategory,
    activePOI: s.activePOI,
    showPoiImage: s.showPoiImage,
  }));
  const categories = [
    'transit',
    'schools',
    'recreation',
    'restaurants',
    'shops',
  ];
  const POIS = activeCategory ? mapData[activeCategory] : [];
  function categoryClick(ctg) {
    syncMultipleStatesWithSocket({
      activePOI: null,
      activeCategory: ctg,
    });
  }

  useEffect(() => {
    setTimeout(() => {
      setSectionsOpen(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (activePOI || activeCategory) {
      syncMultipleStatesWithSocket({
        showPoiImage: false,
      });
    }

    if (sectionsOpen) {
      syncMultipleStatesWithSocket({
        activePOI: null,
      });
    }
  }, [activePOI, activeCategory, sectionsOpen]);

  return (
    <MapWrapper>
      <MenuButton
        onMenuClicked={() => {
          syncMultipleStatesWithSocket({
            menuOpen: true,
            menuIndex: null,
          });
        }}
      />

      <ImmersionMapControl
        activeCategory={activeCategory}
        activePOI={activePOI}
        onDestroy={() => {
          syncMultipleStatesWithSocket({
            activePOI: null,
            activeCategory: null,
          });
        }}
      />
      <Sections>
        <ListButtonIcon
          strokeColor={globalStyles.colors.white}
          bgColor={globalStyles.colors.black}
          strokeWidth={1}
          className={'mapIcon'}
          onClick={() => {
            setSectionsOpen(!sectionsOpen);
          }}
        />
      </Sections>
      <List
        style={{
          opacity: sectionsOpen ? 1 : 0,
          pointerEvents: sectionsOpen ? 'all' : 'none',
        }}
      >
        <h1>CATEGORIES</h1>
        {categories.map((ctg, i) => {
          return (
            <button
              key={`ctgBtn${i}`}
              onClick={() => {
                categoryClick(ctg);
                setTimeout(() => {
                  setSectionsOpen(false);
                }, 800);
              }}
              style={{
                border: activeCategory === ctg ? '2px solid white' : 'none',
                cursor: activeCategory === ctg ? 'default' : 'pointer',
              }}
            >
              {ctg}
            </button>
          );
        })}
        <CloseIcon
          className="closeIcon"
          strokeColor={globalStyles.colors.black}
          bgColor={globalStyles.colors.white}
          strokeWidth={2}
          onClick={() => {
            setSectionsOpen(false);
          }}
        />
      </List>
      <List
        style={{
          opacity: !sectionsOpen && activeCategory !== null ? 1 : 0,
          pointerEvents:
            !sectionsOpen && activeCategory !== null ? 'all' : 'none',
        }}
        className="poisList"
      >
        <h1>{activeCategory ?? ''}</h1>
        {POIS?.map((poi, i) => {
          return (
            <button
              key={`poiBtn${i}`}
              onClick={() => {
                syncMultipleStatesWithSocket({
                  activePOI: poi?.index,
                });
              }}
              style={{
                border: activePOI === poi?.index ? '2px solid white' : 'none',
                cursor: activePOI === poi?.index ? 'default' : 'pointer',
              }}
            >
              {poi?.poiNumber} - {poi?.name}{' '}
              {poi.lineColor ? (
                <span style={{ color: poi.lineColor }}>&#8718;</span>
              ) : null}
            </button>
          );
        })}
        <CloseIcon
          className="closeIcon"
          strokeColor={globalStyles.colors.black}
          bgColor={globalStyles.colors.white}
          strokeWidth={2}
          onClick={() => {
            setSectionsOpen(true);
          }}
        />
      </List>
      {POIS &&
        POIS.map((poi: any, i: any) => {
          if (!poi?.imageUrl) return;

          if (activePOI !== poi?.index) return;

          return (
            <ThumbnailContainer key={`thumbnail${i}`}>
              <img className="thumbnail" src={poi.imageUrl} alt="thumbnail" />
              <Button
                label={showPoiImage ? 'Hide Image' : 'Show Image'}
                active={showPoiImage}
                style={{
                  height: '3rem',
                  width: '100%',
                  marginTop: '0.5rem',
                }}
                onClick={() =>
                  syncMultipleStatesWithSocket({
                    showPoiImage: !showPoiImage,
                  })
                }
              />
            </ThumbnailContainer>
          );
        })}
    </MapWrapper>
  );
}

export default Maps;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.main};

  .poisList {
    gap: 0.6rem;

    & h1 {
      color: white;
      text-transform: uppercase;
    }
  }

  .closeIcon {
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    margin: 25px;
    pointer-events: all;
    cursor: pointer;
  }
`;

const Sections = styled.div`
  position: absolute;
  width: 10%;
  height: clamp(30px, 4rem, 50px);
  top: ${globalStyles.margin};
  left: ${globalStyles.margin};
  z-index: 1;
  display: flex;
  align-items: flex-start;
  gap: ${globalStyles.margin};

  .mapIcon {
    pointer-events: all;
    cursor: pointer;
    height: 100%;
    width: auto;
  }
`;

const List = styled.div`
  position: absolute;
  width: 30%;
  height: fit-content;
  left: ${globalStyles.margin};
  bottom: ${globalStyles.margin};
  padding: 2vh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgb(99 118 42 / 75%);
  border-radius: ${globalStyles.borderRadius};
  transition: opacity 1s ease-in-out;

  & h1 {
    color: white;
    transform: translateX(1rem);
  }

  & button {
    min-width: 8rem;
    width: fit-content;
    font-family: main;
    text-transform: capitalize;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    transition: border-color 0.5s ease-in-out;
    text-align: left;
    color: white;
    background: none;
  }
`;

const ThumbnailContainer = styled.div`
  position: absolute;
  left: calc(30% + ${globalStyles.margin} * 2);
  bottom: calc(${globalStyles.margin} * 2.75);
  height: 10%;
  width: 12%;
  background-color: rgb(99 118 42 / 75%);
  border-radius: ${globalStyles.borderRadius};
  transition: opacity 1s ease-in-out;

  .thumbnail {
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    border-radius: calc(${globalStyles.borderRadius} * 1.25);
    object-fit: cover;
  }
`;

const mapData = {
  toronto: [
    {
      type: 'point',
      coords: [-79.383935, 43.653482],
      name: 'Toronto',
    },
  ],
  rexall: [
    {
      type: 'point',
      coords: [-79.477694, 43.670094],
      name: 'Origin',
    },
  ],
  transit: [
    {
      name: '512 Streetcar',
      coords: [
        [-79.47177, 43.67201],
        [-79.471142, 43.67179],
        [-79.467527, 43.672562],
        [-79.462455, 43.673656],
        [-79.458241, 43.674606],
        [-79.454318, 43.675459],
        [-79.450277, 43.67635],
        [-79.445935, 43.677307],
        [-79.441643, 43.678266],
        [-79.437248, 43.679238],
        [-79.43335, 43.680095],
        [-79.428557, 43.681111],
        [-79.424602, 43.681934],
        [-79.420811, 43.682632],
        [-79.417455, 43.683315],
      ],
      poiNumber: 1,
      lineColor: 'red',
      lineWidth: 3,
      lineOpacity: 0,
      source: 'street-car',
      index: 100,
    },
    {
      name: 'Subway Bloor–Danforth Line',
      coords: [
        [-79.524305, 43.645377],
        [-79.511143, 43.648248],
        [-79.494088, 43.649714],
        [-79.483812, 43.649882],
        [-79.475726, 43.651693],
        [-79.467856, 43.653678],
        [-79.459485, 43.655581],
        [-79.452894, 43.657003],
        [-79.44261, 43.659101],
        [-79.43536, 43.660312],
        [-79.425987, 43.662391],
        [-79.418244, 43.664142],
        [-79.41102, 43.665992],
        [-79.404615, 43.668209],
        [-79.398741, 43.668393],
        [-79.390172, 43.67019],
        [-79.385665, 43.670818],
        [-79.376465, 43.672212],
        [-79.372983, 43.672137],
        [-79.372684, 43.672174],
        [-79.372273, 43.672218],
        [-79.371666, 43.672334],
        [-79.371262, 43.672442],
        [-79.370799, 43.672588],
        [-79.368806, 43.673599],
        [-79.358424, 43.676789],
        [-79.352535, 43.678195],
        [-79.344822, 43.679865],
        [-79.330188, 43.682546],
        [-79.323019, 43.684103],
        [-79.312579, 43.686259],
        [-79.3016, 43.689031],
      ],
      lineColor: 'green',
      lineWidth: 3,
      lineOpacity: 0,
      source: 'bloor-danforth',
      poiNumber: 2,
      index: 101,
    },
    {
      name: 'UP Express / GO Transit',
      coords: [
        [-79.380474, 43.644302],
        [-79.401003, 43.640296],
        [-79.408951, 43.640123],
        [-79.425056, 43.640837],
        [-79.442943, 43.64968],
        [-79.456934, 43.66396],
        [-79.459247, 43.666487],
        [-79.468001, 43.675214],
        [-79.471721, 43.678691],
        [-79.472991, 43.679741],
        [-79.474987, 43.681119],
        [-79.476619, 43.682103],
        [-79.478615, 43.683186],
        [-79.481337, 43.684563],
        [-79.483514, 43.685547],
        [-79.487233, 43.687351],
        [-79.489002, 43.688237],
        [-79.496668, 43.692009],
        [-79.501388, 43.694338],
        [-79.51256, 43.699986],
      ],
      lineColor: 'salmon',
      lineWidth: 3,
      lineOpacity: 0,
      poiNumber: 3,
      source: 'up-express',
      index: 102,
    },
    {
      name: 'West Toronto Rail Path',
      poiNumber: 4,
      coords: [
        [-79.380364, 43.644552],
        [-79.393077, 43.642352],
        [-79.399238, 43.640806],
        [-79.40579, 43.640152],
        [-79.412187, 43.640428],
        [-79.424722, 43.640981],
        [-79.434044, 43.645116],
        [-79.438333, 43.647161],
        [-79.442395, 43.649651],
        [-79.443548, 43.650799],
        [-79.445854, 43.653095],
        [-79.450466, 43.657687],
        [-79.454998, 43.662392],
        [-79.465204, 43.672675],
        [-79.465421, 43.673673],
        [-79.4655, 43.674357],
        [-79.4655, 43.674429],
      ],
      lineColor: 'yellow',
      lineWidth: 3,
      lineOpacity: 0,
      source: 'west-toronto',
      index: 103,
    },
  ],
  schools: [
    {
      index: 0,
      poiNumber: 1,
      type: 'point',
      coords: [-79.478944, 43.675903],
      zoom: 15,
      name: 'Harwood Public School',
    },
    {
      index: 1,
      poiNumber: 2,
      type: 'point',
      coords: [-79.48687, 43.66313],
      zoom: 15,
      name: 'King George Junior Public School',
    },
    {
      index: 2,
      poiNumber: 3,
      type: 'point',
      coords: [-79.47236, 43.662826],
      zoom: 15,
      name: 'The Clover School Montessori',
    },
    {
      index: 3,
      poiNumber: 4,
      type: 'point',
      coords: [-79.473168, 43.661405],
      zoom: 15,
      name: 'Annette Street Junior & Senior Public School',
    },
    {
      index: 4,
      poiNumber: 5,
      type: 'point',
      coords: [-79.474533, 43.660623],
      zoom: 15,
      name: 'Saint Cecilia Catholic Elementary School',
    },
    {
      index: 5,
      poiNumber: 6,
      type: 'point',
      coords: [-79.472249, 43.661409],
      zoom: 15,
      name: 'High Park Alternative Junior School',
    },
  ],
  recreation: [
    {
      index: 6,
      poiNumber: 1,
      type: 'point',
      coords: [-79.4781764035009, 43.6686796354456],
      zoom: 15,
      name: 'Runnymede Park',
      imageUrl: '/assets/images/map/Runnymede Park.webp',
    },
    {
      index: 7,
      poiNumber: 2,
      type: 'point',
      coords: [-79.4781764035009, 43.6686796354456],
      zoom: 15,
      name: 'Humber river',
      imageUrl: '/assets/images/map/Humber River.webp',
    },
    {
      index: 8,
      poiNumber: 3,
      type: 'point',
      coords: [-79.46364724750678, 43.64732396104362],
      zoom: 15,
      name: 'High Park',
      imageUrl: '/assets/images/map/High Park.webp',
    },
    {
      index: 9,
      poiNumber: 4,
      type: 'point',
      coords: [-79.477377, 43.668438],
      zoom: 15,
      name: 'George Bell Arena',
    },
    {
      index: 10,
      poiNumber: 5,
      type: 'point',
      coords: [-79.497588, 43.675461],
      zoom: 15,
      name: 'Smythe Park Outdoor Pool',
    },
    {
      index: 11,
      poiNumber: 6,
      type: 'point',
      coords: [-79.507931, 43.669854],
      zoom: 15,
      name: 'Lambton Golf & Country Club',
    },
    {
      index: 12,
      poiNumber: 7,
      type: 'point',
      coords: [-79.468303, 43.673337],
      zoom: 15,
      name: 'BATL Axe Throwing',
    },
    {
      index: 13,
      poiNumber: 8,
      type: 'point',
      coords: [-79.467981, 43.672901],
      zoom: 15,
      name: 'Anytime Fitness',
    },
    {
      index: 14,
      poiNumber: 9,
      type: 'point',
      coords: [-79.460406, 43.672372],
      zoom: 15,
      name: "Felix's Swim School",
    },
    {
      index: 15,
      poiNumber: 10,
      type: 'point',
      coords: [-79.464533, 43.665603],
      zoom: 15,
      name: 'Junction',
      imageUrl: '/assets/images/map/Junction.webp',
    },
  ],
  restaurants: [
    {
      index: 16,
      poiNumber: 1,
      type: 'point',
      coords: [-79.47873, 43.673579],
      zoom: 15,
      name: 'Junction Craft Brewery',
    },
    {
      index: 17,
      poiNumber: 2,
      type: 'point',
      coords: [-79.483169, 43.669836],
      zoom: 15,
      name: 'High Park Brewery',
      imageUrl: '/assets/images/map/High Park Brewery.webp',
    },
    {
      index: 18,
      poiNumber: 3,
      type: 'point',
      coords: [-79.48073147813776, 43.66998221622898],
      zoom: 15,
      name: 'Stockyard Grind',
      imageUrl: '/assets/images/map/Stockyard Grind.webp',
    },
    {
      index: 19,
      poiNumber: 4,
      type: 'point',
      coords: [-79.470965, 43.67212],
      zoom: 15,
      name: "Shoeless Joe's",
    },
    {
      index: 20,
      poiNumber: 5,
      type: 'point',
      coords: [-79.46741, 43.671564],
      zoom: 15,
      name: 'Swiss Chalet',
    },
    {
      index: 21,
      poiNumber: 6,
      type: 'point',
      coords: [-79.46553374861622, 43.665471323882414],
      zoom: 15,
      name: 'Nodo Junction',
      imageUrl: '/assets/images/map/Nodo.webp',
    },
    {
      index: 22,
      poiNumber: 7,
      type: 'point',
      coords: [-79.466806, 43.664705],
      zoom: 15,
      name: 'Playa Cabana Cantina',
    },
    {
      index: 23,
      poiNumber: 8,
      type: 'point',
      coords: [-79.469885, 43.664667],
      zoom: 15,
      name: 'Pizzeria Libretto',
    },
    {
      index: 24,
      poiNumber: 9,
      type: 'point',
      coords: [-79.47185950844401, 43.66574965719864],
      zoom: 15,
      name: 'Dirty Food',
      imageUrl: '/assets/images/map/Dirty Food.webp',
    },
    {
      index: 25,
      poiNumber: 10,
      type: 'point',
      coords: [-79.46556713232954, 43.6657270443641],
      zoom: 15,
      name: 'Cornerstone',
      imageUrl: '/assets/images/map/Cornerstone.webp',
    },
    {
      index: 26,
      poiNumber: 11,
      type: 'point',
      coords: [-79.46950481670797, 43.66532447309895],
      zoom: 15,
      name: 'Famous Last Words',
      imageUrl: '/assets/images/map/Famous Last Words.webp',
    },
    {
      index: 27,
      poiNumber: 12,
      type: 'point',
      coords: [-79.4830303064125, 43.66996261218737],
      zoom: 15,
      name: 'Starving Artist',
      imageUrl: '/assets/images/map/Starving Artist.webp',
    },
    {
      index: 28,
      poiNumber: 13,
      type: 'point',
      coords: [-79.48868329644894, 43.66815491026957],
      zoom: 15,
      name: 'Bakery Monte',
      imageUrl: '/assets/images/map/Bakery Monte.webp',
    },
  ],
  shops: [
    {
      index: 29,
      poiNumber: 1,
      type: 'point',
      coords: [-79.469886, 43.673402],
      zoom: 15,
      name: 'Stockyards Village Mall',
    },
    {
      index: 30,
      poiNumber: 2,
      type: 'point',
      coords: [-79.47052277512749, 43.672713725747045],
      zoom: 15,
      name: 'Nations Experience',
      imageUrl: '/assets/images/map/Nations Experience.webp',
    },
    {
      index: 31,
      poiNumber: 3,
      type: 'point',
      coords: [-79.472154, 43.67062],
      zoom: 15,
      name: 'Canadian Tire',
    },
    {
      index: 32,
      poiNumber: 4,
      type: 'point',
      coords: [-79.472983, 43.670501],
      zoom: 15,
      name: 'LCBO',
    },
    {
      index: 33,
      poiNumber: 5,
      type: 'point',
      coords: [-79.472713, 43.669882],
      zoom: 15,
      name: 'The Beer Store',
    },
    {
      index: 34,
      poiNumber: 6,
      type: 'point',
      coords: [-79.4732, 43.669654],
      zoom: 15,
      name: 'Metro',
    },
    {
      index: 35,
      poiNumber: 7,
      type: 'point',
      coords: [-79.467671, 43.671761],
      zoom: 15,
      name: 'Shoppers Drug Mart',
    },
    {
      index: 36,
      poiNumber: 8,
      type: 'point',
      coords: [-79.46372567386943, 43.669928605285676],
      zoom: 15,
      name: 'Organic Garage',
      imageUrl: '/assets/images/map/Organic Garage.webp',
    },
    {
      index: 37,
      poiNumber: 9,
      type: 'point',
      coords: [-79.46846768875409, 43.667136184284026],
      zoom: 15,
      name: 'Sweet Potato',
      imageUrl: '/assets/images/map/Sweet Potato.webp',
    },
    {
      index: 38,
      poiNumber: 10,
      type: 'point',
      coords: [-79.471077, 43.662575],
      zoom: 15,
      name: 'The Good Neighbour Espresso Bar',
    },
    {
      index: 39,
      poiNumber: 11,
      type: 'point',
      coords: [-79.48451711834853, 43.66824805912397],
      zoom: 15,
      name: 'Walmart',
      imageUrl: '/assets/images/map/Walmart.webp',
    },
    {
      index: 40,
      poiNumber: 12,
      type: 'point',
      coords: [-79.495917, 43.66537],
      zoom: 15,
      name: 'Loblaws',
    },
    {
      index: 41,
      poiNumber: 13,
      type: 'point',
      coords: [-79.486119, 43.667557],
      zoom: 15,
      name: 'Starbucks',
    },
    {
      index: 42,
      poiNumber: 14,
      type: 'point',
      coords: [-79.496731, 43.66522],
      zoom: 15,
      name: 'Starbucks',
    },
    {
      index: 43,
      poiNumber: 15,
      type: 'point',
      coords: [-79.4731700638607, 43.66989309889302],
      zoom: 15,
      name: 'Metro Plaza',
      imageUrl: '/assets/images/map/Metro Plaza.webp',
    },
    {
      index: 44,
      poiNumber: 16,
      type: 'point',
      coords: [-79.4708302611766, 43.67297454583034],
      zoom: 15,
      name: 'Stockyards',
      imageUrl: '/assets/images/map/Stockyards.webp',
    },
    {
      index: 45,
      poiNumber: 17,
      type: 'point',
      coords: [-79.46562144793609, 43.66549044605336],
      zoom: 15,
      name: 'Type Bookstore',
      imageUrl: '/assets/images/map/Type Bookstore.webp',
    },
  ],
};

export default mapData;

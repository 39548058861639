/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { useLocation } from 'react-router-dom';
import { Vector3 } from 'three';

import SphericalTour from 'common/src/canvas_components/SphericalTour';
import AmenitiesModel from 'common/src/canvas_components/AmenitiesModel';
import ApartmentModel from 'common/src/canvas_components/ApartmentModel';
import SocketsOrbitControls from 'common/src/canvas_components/SocketsOrbitControls';

import HandleLoadingProgress from 'common/src/utils/loading';
import globalStyles from 'common/src/data/globalStyles';
import type { TInitialState } from '../../data/initialState';
import type { TUnit } from '../../lib/formatData';
import { organizeAspects } from '../../utils/functions';
import View360 from '../View360';

const baseControlProps = {
  makeDefault: true,
  enablePan: false,
  minAzimuthAngle: 0.2,
  maxAzimuthAngle: Number.POSITIVE_INFINITY,
  minPolarAngle: 0,
  maxPolarAngle: 1.3,
  enableZoom: true,
  rotateSpeed: 0.5,
  minZoom: 0,
  maxDistance: 200,
  minDistance: 50,
};

function MainCanvas({
  controlsCatcher,
  rexallSection,
  amenityIndex,
  amenityRenderOpen,
  unitCardOpen,
  unitCardSection,
  selectedUnit,
  background = null,
  tourIndex,
  setTourIndex,
  room,
  socketId,
  palette,
  ...props
}: Pick<
  TInitialState,
  | 'controlsCatcher'
  | 'rexallSection'
  | 'amenityIndex'
  | 'amenityRenderOpen'
  | 'unitCardOpen'
  | 'unitCardSection'
  | 'palette'
  | 'tourIndex'
> & {
  room?: any;
  socketId?: string;
  selectedUnit?: TUnit;
  background?: string | null;
  setTourIndex: (v: number) => void;
}) {
  const [viewIndex, setViewIndex] = useState(0);
  const [controlProps, setControlProps] = useState(baseControlProps);
  const { pathname } = useLocation();
  const controlsRef = useRef();

  const totalViews = 3;

  const maxLevel = 11;

  const height = selectedUnit
    ? Math.floor((totalViews / maxLevel) * selectedUnit.level)
    : 0;

  const views = selectedUnit
    ? organizeAspects([...selectedUnit['aspect']])
    : ['N'];

  const rotation = {
    W: -2.7,
    N: -1.2,
    E: 0.2,
    S: 2,
  };

  const limit = {
    1: -0.63,
    2: -1.7,
    3: -3.2,
    4: -4.4,
  };

  const isViewVisible =
    rexallSection === 'residences' && unitCardOpen && unitCardSection === 2;

  const isTour = pathname === '/tour' && !isViewVisible;

  function getControlsProps() {
    if (isViewVisible) {
      const centerHor = 1.58;
      return {
        makeDefault: true,
        enablePan: false,
        minAzimuthAngle: limit[views.length],
        maxAzimuthAngle: 0,
        minPolarAngle: centerHor,
        maxPolarAngle: centerHor,
        enableZoom: false,
        rotateSpeed: -0.5,
        minZoom: 0.5,
        maxDistance: 500,
        minDistance: 50,
      };
    }
    if (isTour) {
      return {
        makeDefault: true,
        enablePan: false,
        minAzimuthAngle: Number.POSITIVE_INFINITY,
        maxAzimuthAngle: Number.POSITIVE_INFINITY,
        minPolarAngle: 0,
        maxPolarAngle: Math.PI,
        enableZoom: false,
        rotateSpeed: -0.5,
        minZoom: 0,
        maxDistance: 500,
        minDistance: 50,
      };
    }

    return baseControlProps;
  }

  useEffect(() => {
    setViewIndex(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnit]);

  useEffect(() => {
    setControlProps(getControlsProps());
  }, [pathname, rexallSection, unitCardOpen, unitCardSection]);

  // Add an effect to enforce zoom limits when controls change
  useEffect(() => {
    if (controlsRef.current) {
      const controls = controlsRef.current as any;
      if (
        controls.minDistance !== undefined &&
        controls.maxDistance !== undefined
      ) {
        // Ensure the camera distance is within bounds
        if (controls.getDistance) {
          const currentDistance = controls.getDistance();
          if (currentDistance > controls.maxDistance) {
            controls.dollyOut(currentDistance / controls.maxDistance);
          } else if (currentDistance < controls.minDistance) {
            controls.dollyIn(controls.minDistance / currentDistance);
          }
        }
      }
    }
  }, [controlProps]);

  return (
    <CanvasWr {...props}>
      <Suspense
        fallback={
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'white',
              fontSize: '2rem',
            }}
          >
            ...loading
          </div>
        }
      >
        <Canvas
          onCreated={(state) =>
            state &&
            state.events &&
            state.events.connect &&
            state.events.connect(controlsCatcher)
          }
          camera={{
            position: new Vector3(100, 100, 100),
            fov: 49,
            near: 10,
            far: 2000,
          }}
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <HandleLoadingProgress
            totalItems={40}
            logassets={false}
            onloaded={() => console.log('loaded')}
          />
          <AmenitiesModel
            controls={controlsRef.current}
            amenityIndex={amenityIndex}
            show={
              rexallSection === 'amenities' &&
              amenityIndex !== null &&
              amenityRenderOpen
            }
            updateCamera
          />
          {selectedUnit && unitCardOpen && unitCardSection === 0 && (
            <ApartmentModel
              selectedUnit={selectedUnit}
              show={
                rexallSection === 'residences' &&
                unitCardOpen &&
                unitCardSection === 0
              }
            />
          )}
          <SphericalTour
            textures={[
              '/assets/images/tour/light/bedroom1-cam-1.webp',
              '/assets/images/tour/light/bedroom1-cam-2.webp',
              '/assets/images/tour/light/bedroom2-cam-1.webp',
              '/assets/images/tour/light/bedroom2-cam-2.webp',
              '/assets/images/tour/light/hallway-cam-1.webp',
              '/assets/images/tour/light/living-cam-1.webp',
              '/assets/images/tour/light/living-cam-2.webp',
              '/assets/images/tour/medium/bedroom1-cam-1.webp',
              '/assets/images/tour/medium/bedroom1-cam-2.webp',
              '/assets/images/tour/medium/bedroom2-cam-1.webp',
              '/assets/images/tour/medium/bedroom2-cam-2.webp',
              '/assets/images/tour/medium/hallway-cam-1.webp',
              '/assets/images/tour/medium/living-cam-1.webp',
              '/assets/images/tour/medium/living-cam-2.webp',
              '/assets/images/tour/dark/bedroom1-cam-1.webp',
              '/assets/images/tour/dark/bedroom1-cam-2.webp',
              '/assets/images/tour/dark/bedroom2-cam-1.webp',
              '/assets/images/tour/dark/bedroom2-cam-2.webp',
              '/assets/images/tour/dark/hallway-cam-1.webp',
              '/assets/images/tour/dark/living-cam-1.webp',
              '/assets/images/tour/dark/living-cam-2.webp',
            ]}
            controls={false}
            activeIndex={tourIndex}
            show={pathname === '/tour'}
            hotspotClick={(x) => {
              setTourIndex(indexMap[palette][x]);
            }}
          />
          <View360
            activeIndex={viewIndex}
            show={isViewVisible}
            initialRotation={[0, 0, 0]}
          />
          <SocketsOrbitControls
            controlProps={controlProps}
            role="emitter"
            room={room}
            id={socketId}
            ref={controlsRef}
          />
        </Canvas>
      </Suspense>
    </CanvasWr>
  );
}

const CanvasWr = styled.div`
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
`;

const Background = styled.div<{ $url: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${(p) => p.$url});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
`;

const Blur = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${globalStyles.colors.black}33;
  backdrop-filter: blur(10px);
`;

export default MainCanvas;

const indexMap = {
  0: [0, 1, 2, 3, 4, 5, 6],
  1: [7, 8, 9, 10, 11, 12, 13],
  2: [14, 15, 16, 17, 18, 19, 20],
};

import { useRef, useEffect, useState, Children } from 'react';

import SVGOriginLogo from 'common/src/components/SVGOriginLogo';
import globalStyles from 'common/src/data/globalStyles';
import styled from 'styled-components';
import MapMarker from 'common/src/components/MapMarker';
import Map from 'common/src/components/Map/Map';
import mapData from 'common/src/data/mapData';
import gsap from 'gsap';
import mapboxgl from 'mapbox-gl';

export function normalizeNumber(
  number: number,
  min: number,
  max: number,
): number {
  return (number - min) / (max - min);
}

export function clamp(number: number, min: number, max: number): number {
  return Math.min(Math.max(number, min), max);
}

export interface IMapControllerProps {
  category: string;
  activeId: number;
}

function MapController({ category, activeId }: IMapControllerProps) {
  const [loaded, setLoaded] = useState(false);
  const mapRef = useRef<any>(null);
  const timeline = gsap.timeline();
  const originMarkerRef = useRef<HTMLElement | null>(null);
  const [activePOIName, setActivePOIName] = useState<string | null>(null);
  const activePOIRef = useRef<HTMLElement | null>(null);

  const markerData = mapData.rexall[0];
  const poisArr = [
    ...mapData.schools,
    ...mapData.recreation,
    ...mapData.restaurants,
    ...mapData.shops,
  ];

  const linesData = [...mapData.transit];

  function getCategory(index: number) {
    const poiCategories = ['schools', 'recreation', 'restaurants', 'shops'];

    for (const category of poiCategories) {
      if (mapData[category].some((poi) => poi.index === index)) {
        return category;
      }
    }

    return undefined;
  }

  function getColor(category: string) {
    switch (category) {
      case 'schools':
        return globalStyles.colors.orange;
      case 'recreation':
        return globalStyles.colors.medium;
      case 'restaurants':
        return globalStyles.colors.second;
      case 'shops':
        return globalStyles.colors.accent;
      default:
        break;
    }
  }

  function getPOINameByIndex(index: number): string {
    const poi = poisArr.find((p) => p.index === index);
    return poi ? poi.name || `POI ${poi.poiNumber}` : `POI ${index}`;
  }

  const markerArr = poisArr.map<any>((obj) => {
    const category = getCategory(obj.index);

    return {
      component: (
        <POIMarkerWrapper
          id={`poi-wrapper-${obj.index}`}
          data-name={obj.name || `POI ${obj.poiNumber}`}
        >
          <MapMarker
            circleradius={20}
            text={obj.poiNumber}
            textcolor={globalStyles.colors.white}
            textsize={22}
            bgcolor={getColor(category as string)}
            bordercolor={getColor(category as string)}
            width="50px"
            height="50px"
            className="poiSelector"
            id={`poi-marker-${obj.index}`}
            data-category={category}
          />
          <POILabel
            className="poi-label"
            color={getColor(category as string)}
            style={{ opacity: 1 }}
          >
            <span>{obj.name || `POI ${obj.poiNumber}`}</span>
            <span className="category">{category}</span>
          </POILabel>
        </POIMarkerWrapper>
      ),
      x: obj.coords[0],
      y: obj.coords[1],
    };
  });

  function getMarkersArr(callback) {
    const markers = Array.from(document.getElementsByClassName('poiSelector'));
    markers.forEach((marker: Element) => {
      callback(marker);
    });
  }

  useEffect(() => {
    if (loaded) {
      originMarkerRef.current = document.getElementById('origin-marker');

      if (originMarkerRef.current) {
        gsap.set(originMarkerRef.current, {
          zIndex: 1000,
        });
      }
    }
  }, [loaded]);

  useEffect(() => {
    if (mapRef.current && loaded) {
      const map = mapRef.current.getRef();
      setTimeout(() => {
        map.flyTo(mapCameraSettings.base);
      }, 1850);
    }
  }, [loaded]);

  const updatePOILabels = () => {
    const allLabels = document.querySelectorAll('.poi-label');
    allLabels.forEach((label) => {
      gsap.to(label, {
        opacity: 0,
        y: 0,
        duration: 0.3,
      });
    });

    if (activeId !== null) {
      const activeWrapper = document.getElementById(`poi-wrapper-${activeId}`);
      if (activeWrapper) {
        const activeLabel = activeWrapper.querySelector('.poi-label');
        if (activeLabel) {
          gsap.to(activeLabel, {
            opacity: 1,
            y: 5,
            duration: 0.5,
            delay: 0.2,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getRef();
    const lineLayers = mapRef.current.getLineLayers();
    const layerIDS = Object.keys(lineLayers);
    if (category === null) {
      map.flyTo(mapCameraSettings.base);
      return;
    } else if (category === 'transit') {
      getMarkersArr((marker) => {
        gsap.to(marker, {
          opacity: 0,
          scale: 1,
        });
      });
      if (layerIDS.length) {
        timeline.to(
          {},
          {
            duration: 2,
            onUpdate: () => {
              const progress = timeline.progress();
              const normalized = normalizeNumber(progress, 0, 1);
              layerIDS.forEach((id, i) => {
                const color = linesData[i].lineColor;
                mapRef.current.updateLineLayerOpacity(id, normalized);
                mapRef.current.updateLineGradient(id, [
                  'interpolate',
                  ['linear'],
                  ['line-progress'],
                  clamp(normalized, 0, 0.999),
                  `${color}`,
                  clamp(normalized + 0.001, 0.001, 1),
                  `transparent`,
                ]);
              });
            },
          },
        );
      }
      map.flyTo(mapCameraSettings.transit);
      updatePOILabels();
      return;
    } else {
      map.flyTo(mapCameraSettings[category]);
    }
    getMarkersArr((marker) => {
      const cat = marker.dataset.category;
      gsap.to(marker, {
        opacity: cat === category ? 1 : 0,
        scale: cat === category ? markerScale[category] : 1,
      });
    });
    if (layerIDS.length) {
      if (map.getPaintProperty(layerIDS[0], 'line-opacity') === 0) return;
      timeline.to(
        {},
        {
          duration: 2,
          onUpdate: () => {
            const progress = timeline.progress();
            const normalized = normalizeNumber(progress, 0, 1);
            const opacity = 1 - normalized;
            layerIDS.forEach((id, i) => {
              const color = linesData[i].lineColor;
              mapRef.current.updateLineLayerOpacity(id, opacity);
              mapRef.current.updateLineGradient(id, [
                'interpolate',
                ['linear'],
                ['line-progress'],
                clamp(opacity, 0, 0.999),
                `${color}`,
                clamp(opacity + 0.001, 0.001, 1),
                `transparent`,
              ]);
            });
          },
        },
      );
    }
    updatePOILabels();
  }, [category]);

  useEffect(() => {
    if (category === null) return;
    const map = mapRef.current.getRef();
    if (activeId === null) {
      map.flyTo(mapCameraSettings[category]);
      getMarkersArr((marker) => {
        const cat = marker.dataset.category;
        if (category !== cat) return;
        gsap.to(marker, {
          opacity: 1,
          scale: markerScale[category],
        });
      });
      updatePOILabels();
      return;
    } else {
      const data =
        category === 'transit'
          ? {
              center: mapCameraSettings.transit.center,
              zoom: mapCameraSettings.transit.zoom,
            }
          : mapData[category].filter((obj) => obj.index === activeId)[0];

      setActivePOIName(getPOINameByIndex(activeId));

      if (category !== 'transit') {
        const originCoords = markerData.coords;
        const poiCoords = data.coords;

        // Calculate the bounds that will fit both points
        const fitBounds = () => {
          // Add padding to ensure points aren't right at the edge
          const padding = 100;

          // Create a bounds object that includes both points
          const bounds = new mapboxgl.LngLatBounds()
            .extend(originCoords as any)
            .extend(poiCoords);

          // Fly to the bounds with padding
          map.fitBounds(bounds, {
            padding: {
              top: padding,
              bottom: padding,
              left: padding,
              right: padding,
            },
            maxZoom: data.zoom, // Don't zoom in more than the POI's default zoom
            curve: 1.2,
            speed: 0.8,
          });
        };

        // Calculate distance between origin and POI
        const dx = originCoords[0] - poiCoords[0];
        const dy = originCoords[1] - poiCoords[1];
        const distance = Math.sqrt(dx * dx + dy * dy);

        // Threshold to determine when to use fitBounds vs. centered view
        const threshold = 0.008; // Adjust based on testing

        if (distance > threshold) {
          // If points are far apart, fit both in view
          fitBounds();
        } else {
          // If points are close, just center on the POI
          map.flyTo({
            center: poiCoords,
            zoom: data.zoom,
            curve: 1.2,
            speed: 0.8,
          });
        }
      } else {
        map.flyTo({
          center: data.center,
          zoom: data.zoom,
          curve: 1.2,
          speed: 0.8,
        });
      }
    }

    getMarkersArr((marker) => {
      const id = marker.id;
      const cat = marker.dataset.category;
      if (category !== cat) return;
      gsap.to(marker, {
        opacity: id === `poi-marker-${activeId}` || activeId === null ? 1 : 0.2,
        scale: id === `poi-marker-${activeId}` ? 2 : markerScale[category],
      });
    });

    updatePOILabels();
  }, [activeId, category]);

  return (
    <MapControllerPage style={{ opacity: loaded ? 1 : 0 }}>
      <Map
        token={import.meta.env.VITE_MAPBOX_TOKEN}
        styleURL={import.meta.env.VITE_MAPBOX_STYLE}
        ref={mapRef}
        onLoad={() => {
          setLoaded(true);
        }}
        centralMarker={{
          component: (
            <SVGOriginLogo width="100px" height="100px" id="origin-marker" />
          ),
          x: markerData.coords[0],
          y: markerData.coords[1],
        }}
        poiMarkersArr={markerArr}
        linesArr={linesData as any}
      />
    </MapControllerPage>
  );
}

export default MapController;

const MapControllerPage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.main};
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;

  .poiSelector {
    opacity: 0;
    font-family: main;
  }
`;

const POIMarkerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const POILabel = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color || globalStyles.colors.black};
  color: white;
  padding: 3px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  opacity: 0;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: fit-content;
  text-align: center;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .category {
    text-transform: uppercase;
    font-size: 10px;
  }
`;

const mapCameraSettings = {
  base: {
    center: [-79.4775, 43.6704],
    zoom: 15.07,
    curve: 1,
    speed: 1,
  },
  schools: {
    center: [-79.4775, 43.668],
    zoom: 14.32,
    curve: 1,
    speed: 1,
  },
  recreation: {
    center: [-79.478, 43.6646],
    zoom: 13.67,
    curve: 1,
    speed: 1,
  },
  restaurants: {
    center: [-79.4775, 43.6694],
    zoom: 15.07,
    curve: 1,
    speed: 1,
  },
  shops: {
    center: [-79.4775, 43.6685],
    zoom: 14.75,
    curve: 1,
    speed: 1,
  },
  transit: {
    center: [-79.4775, 43.6666],
    zoom: 13.1759,
    curve: 1,
    speed: 1,
  },
};

const markerScale = {
  base: 1,
  schools: 0.5,
  recreation: 0.6,
  restaurants: 0.7,
  shops: 0.5,
};

import styled from 'styled-components';
import Button from 'common/src/components/Button';

function BedsRange({ beds, onChange }) {
  const rooms = [1, 2, 3];

  function handleClick(s) {
    onChange(beds.includes(s) ? beds.filter((b) => b !== s) : [...beds, s]);
  }

  return (
    <Range>
      <Buttons>
        <Button
          onClick={() => {
            onChange([]);
          }}
          active={beds.length !== 0}
          label={'Any'}
          style={{
            width: '20%',
            textTransform: 'capitalize',
          }}
        />
        {rooms.map((n, i) => {
          return (
            <Button
              key={`button${i}`}
              onClick={() => {
                handleClick(n);
              }}
              active={!beds.includes(n)}
              label={String(n)}
              style={{
                width: '20%',
              }}
            />
          );
        })}
      </Buttons>
    </Range>
  );
}

export default BedsRange;

const Range = styled.div`
  position: relative;
  width: 100%;
  min-width: 250px;
  height: 100%;
  font-size: 0.8rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2%;
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
// import { useState } from 'react';
import styled from 'styled-components';

// import { useStore } from '@state/store';
import { aerialMarkersData, TAerialData } from '../../data/aerialData';
import globalStyles from 'common/src/data/globalStyles';

function AerialMap({
  bgImage,
  aerialUp,
  aerialRunning,
  screen = null,
  ...props
}: {
  bgImage: string;
  aerialUp: boolean;
  aerialRunning: boolean;
  [key: string]: any;
}) {
  // const { aerialUp, aerialRunning } = useStore((s) => ({
  //   aerialUp: s.aerialUp,
  //   aerialRunning: s.aerialRunning,
  // }));
  // const [data, setData] = useState<unknown | null>(null);
  // const duration = 0.8;
  // const randomID = `dropShadow${Math.random()}`;

  // useEffect(() => {
  //   if (amenityData && data === null) {
  //     gsap.to('.t', {
  //       opacity: 1,
  //       duration,
  //       onStart: () => {
  //         setData(amenityData);
  //       },
  //     });
  //     return;
  //   }

  //   if (amenityData && data) {
  //     gsap.to('.t', {
  //       opacity: 0,
  //       duration: duration / 2,
  //       onComplete: () => {
  //         setData(amenityData);
  //         gsap.to('.t', {
  //           opacity: 1,
  //           duration: duration / 2,
  //         });
  //       },
  //     });
  //     return;
  //   }

  //   if (amenityData === null && data) {
  //     gsap.to('.t', {
  //       opacity: 0,
  //       duration,
  //       onComplete: () => {
  //         setData(amenityData);
  //       },
  //     });
  //     return;
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [amenityData]);

  return (
    <AerialMapWrapper {...props}>
      <svg x="0" y="0" width="100%" height="100%" viewBox={`0 0 8000 1500`}>
        <image
          preserveAspectRatio="xMidYMid slice"
          href={bgImage}
          x="0"
          y="0"
          width="100%"
          height="100%"
        ></image>
        {aerialMarkersData.map(({ x, y, src, line, label }: TAerialData, i) => {
          if (label === 'High Park' && screen === 'immersion') return;

          return (
            <g
              key={`g${i}`}
              transform={`translate(${x}, ${y})`}
              style={{
                opacity: aerialUp && !aerialRunning ? 1 : 0,
                transitionDuration:
                  aerialUp && !aerialRunning ? '0.3s' : '0.3s',
              }}
            >
              <text
                // weight="0.2"
                fill="#fff"
                stroke="#fff"
                strokeOpacity={0}
                fontSize={50}
                x={100}
                y={-50}
                textAnchor="middle"
              >
                {label}
              </text>
              <circle
                cx={100}
                cy={100}
                r={90}
                fill="none"
                strokeWidth={30}
                stroke={globalStyles.colors.white}
                strokeOpacity={0.5}
              />
              <line
                x1={100}
                y1={220}
                x2={100}
                y2={400}
                fill="none"
                strokeWidth={5}
                stroke={globalStyles.colors.white}
                strokeOpacity={1}
              />
              <image width={200} href={src} />

              <circle
                cx={line.x2}
                cy={line.y2}
                r={15}
                fill={globalStyles.colors.white}
              />
            </g>
          );
        })}
      </svg>
    </AerialMapWrapper>
  );
}

export default AerialMap;

const AerialMapWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .t {
    opacity: 0;
  }
  & svg g {
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }
`;

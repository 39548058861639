import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRanger } from 'react-ranger';
import globalStyles from 'common/src/data/globalStyles';
import { useStore } from '../../../../../../../rexall-controller/src/state/store';

function LevelRange({ levelRange, onChange }) {
  const { minLevel, maxLevel } = useStore((s) => ({
    minLevel: s.minLevel,
    maxLevel: s.maxLevel,
  }));

  const [level, setLevel] = useState(levelRange);

  useEffect(() => {
    setLevel(levelRange);
  }, [levelRange]);

  const { getTrackProps, handles } = useRanger({
    values: level,
    onDrag: setLevel,
    onChange: (values) => {
      onChange(values);
    },
    min: minLevel,
    max: maxLevel,
    stepSize: 1,
  });

  return (
    <Wrapper>
      <Labels>
        <Min>{level[0]}</Min>
        <Max>{level[1]}</Max>
      </Labels>
      <RangeContainer>
        <Track {...getTrackProps()}>
          <ActiveTrack
            style={{
              left: `${
                minLevel !== null && maxLevel !== null
                  ? ((level[0] - minLevel) / (maxLevel - minLevel)) * 100
                  : 0
              }%`,
              width: `${
                minLevel !== null && maxLevel !== null
                  ? ((level[1] - level[0]) / (maxLevel - minLevel)) * 100
                  : 0
              }%`,
            }}
          />
          {handles.map(({ getHandleProps }, i) => (
            <Handle {...getHandleProps()} key={i} />
          ))}
        </Track>
      </RangeContainer>
    </Wrapper>
  );
}

export default LevelRange;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RangeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  padding: 0 12px;
`;

const Track = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #e5e5e5;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const ActiveTrack = styled.div`
  position: absolute;
  height: 100%;
  background-color: ${globalStyles.colors.black};
`;

const Handle = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${globalStyles.colors.black};
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }

  &:active {
    transform: translate(-50%, -50%) scale(0.95);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
`;

const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  padding: 0 12px;
`;

const Min = styled.p`
  font-weight: 500;
`;

const Max = styled.p`
  font-weight: 500;
`;

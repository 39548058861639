import styled from 'styled-components';
import Navigation from 'common/src/web_components/Navigation/Navigation';
import { useNavigate } from 'react-router-dom';
import HeartIcon from 'common/src/components/SVGIcons/HeartIcon';
import globalStyles from 'common/src/data/globalStyles';
import { useSocketIO } from 'common/src/providers/sockets/SocketIOProvider';
import { useStore } from '@state/store';
import { useSyncStateWithSocket } from '@hooks/socket/useSyncStateWithSocket';
import { useEffect, useMemo } from 'react';
import { navigations } from 'common/src/data/navigationData';
import gsap from 'gsap';
import AnimatedCircles from 'common/src/components/AnimatedCircles';

function Menu() {
  const navigate = useNavigate();
  const {
    emitSocketEvent,
    socket,
    room,
    isOffline: controllerOff,
    setOffline: setControllerOff,
    onSocketEvent,
  } = useSocketIO();
  const { syncMultipleStatesWithSocket, resyncAllStatesWithSocket } =
    useSyncStateWithSocket();
  const { menuOpen } = useStore((s) => ({
    menuOpen: s.menuOpen,
  }));

  const colors = useMemo(() => {
    return ['#3c4628', '#ffffff', '#171515', '#8e7600'];
  }, []);

  const { navDurationSec, syncStates } = useStore((s) => ({
    navDurationSec: s.navDurationSec,
    syncStates: s.syncStates,
  }));

  useEffect(() => {
    onSocketEvent('reload', () => console.log('RELOAD'));
    syncMultipleStatesWithSocket({ menuOpen: true, menuIndex: null });
    return () => {
      syncMultipleStatesWithSocket({ menuOpen: false, menuIndex: null });
    };
  }, []);

  useEffect(() => {
    if (menuOpen) {
      gsap.to('.menu', { autoAlpha: 1, duration: 0.6, ease: 'power2.inOut' });
    } else if (!menuOpen)
      gsap.to('.menu', { autoAlpha: 0, duration: 1.2, ease: 'power3.inOut' });
  }, [menuOpen]);

  useEffect(() => {
    syncStates({ menuOpen: false });
  }, []);

  return (
    <Wrapper className="menu">
      <AnimatedCircles cue={'now'} colorsArr={colors} />
      <NavWrapper>
        <Navigation
          navDurationSec={navDurationSec}
          handleNavigation={(index) => {
            syncMultipleStatesWithSocket({
              navigating: true,
              menuIndex: index,
            });
          }}
          onNavigationComplete={(index) => {
            if (!import.meta.env.VITE_IS_WEB_BUILD) {
              emitSocketEvent('navigation', {
                path: navigations[index].path,
                id: socket?.id,
                room,
              });
            }
            syncMultipleStatesWithSocket({ menuOpen: false });
          }}
        />
      </NavWrapper>
      {!import.meta.env.VITE_IS_WEB_BUILD ? (
        <ButtonWrapper>
          <ResyncButton
            onClick={() => {
              emitSocketEvent('reload', { room });
              navigate('/');
              syncMultipleStatesWithSocket({ menuOpen: false });
              window.location.reload();
            }}
          >
            Reset
          </ResyncButton>
        </ButtonWrapper>
      ) : (
        <></>
      )}
      <ShareIconWrapper
        onClick={() => {
          syncMultipleStatesWithSocket({ navigating: true });
          emitSocketEvent('navigation', {
            path: '/favorites',
            id: socket?.id,
            room,
          });
          navigate('/favorites');
          syncMultipleStatesWithSocket({ menuOpen: false });
        }}
      >
        <HeartIcon
          strokeWidth={1}
          strokeColor={globalStyles.colors.second}
          bgColor={globalStyles.colors.second}
          heartColor={globalStyles.colors.black}
        />
      </ShareIconWrapper>
      {!import.meta.env.VITE_IS_WEB_BUILD && (
        <ControllerIconWrapper
          onClick={() => {
            if (controllerOff) {
              resyncAllStatesWithSocket();
            }
            setControllerOff(!controllerOff);
          }}
        >
          <ControllerButton className={controllerOff ? 'offCtrl' : 'onCtrl'}>
            <span>ON</span>
          </ControllerButton>
          <ControllerLabel>Controller Mode</ControllerLabel>
        </ControllerIconWrapper>
      )}
    </Wrapper>
  );
}

export default Menu;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  background-color: black;
  z-index: 200000;
  position: absolute;

  .closeIcon {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: ${globalStyles.margin};
    right: ${globalStyles.margin};
    pointer-events: all;
    cursor: pointer;
  }
`;

const NavWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  #nav-group {
    flex: initial !important;
  }
  img {
    width: 16em;
    height: 16em;
    border-radius: 50%;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 8px;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${globalStyles.colors.second};
  border: 3px solid ${globalStyles.colors.second};
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

export const MenuTitle = styled.div`
  color: ${globalStyles.colors.second};
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 81.1%;
  text-transform: uppercase;
  padding-left: 8px;
`;

const ResyncButton = styled(MenuTitle)`
  opacity: 1;
  left: ${globalStyles.margin};
  bottom: ${globalStyles.margin};
  position: absolute;
  font-weight: 100;
`;

const ShareIconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  right: ${globalStyles.margin};
  bottom: ${globalStyles.margin};
  position: absolute;
`;

const ControllerIconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  left: ${globalStyles.margin};
  top: ${globalStyles.margin};
  position: absolute;
`;

const ControllerButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${globalStyles.colors.second};
  border-radius: 50%;
  color: ${globalStyles.colors.white};
  cursor: pointer;
  transition: all 1s ease;

  &.onCtrl {
    transform: rotate(0deg);
    border-radius: 20%;
    opacity: 1;
  }
  &.offCtrl {
    transform: rotate(180deg);
    opacity: 0.5;
  }
`;

const ControllerLabel = styled(MenuTitle)`
  opacity: 1;
  left: 3rem;
  line-height: 1.5rem;
  top: 0.4rem;
  position: absolute;
  font-weight: 100;
`;

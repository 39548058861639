import { createRoot } from 'react-dom/client';

import './style.css';

import App from './App';
import React from 'react';
import { SocketIOProvider } from 'common/src/providers/sockets/SocketIOProvider';
import LandScapePrompt from 'common/src/web_components/LandscapeMode/LandscapeMode';
import { fetchRemoteConfig } from '@utils/firebase';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: `${import.meta.env.VITE_SENTRY_DSN}`,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 50% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container!);

// const SERVER_IP = import.meta.env.MODE === 'development' ?  import.meta.env.VITE_SOCKET_IP : await fetchRemoteConfig('REXALL_SERVER_IP');
const SERVER_IP = import.meta.env.VITE_SOCKET_IP;
// const SERVER_IP = await fetchRemoteConfig('REXALL_SERVER_IP');

root.render(
  <React.Fragment>
    <SocketIOProvider
      url={`http://${SERVER_IP}:${import.meta.env.VITE_SOCKET_PORT}`}
    >
      <App />
      <LandScapePrompt></LandScapePrompt>
    </SocketIOProvider>
  </React.Fragment>,
);

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useStore } from '../../../rexall-controller/src/state/store';
import { useEffect, useRef } from 'react';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useSyncStateWithSocket } from '../../../rexall-controller/src/hooks/socket/useSyncStateWithSocket';
import { EffectFade, Navigation, Pagination } from 'swiper/modules';
import styled from 'styled-components';
import globalStyles from 'common/src/data/globalStyles';

type Props = {
  slides: any[];
  slideEffect?: string;
};

const Carousel = (props: Props) => {
  const slides = props.slides;
  const slideEffect = props.slideEffect;
  const { gallerySection, galleryIndex } = useStore((s) => ({
    gallerySection: s.gallerySection,
    galleryIndex: s.galleryIndex,
  }));
  const { syncMultipleStatesWithSocket } = useSyncStateWithSocket();

  const swiperRef = useRef<any>(null);

  useEffect(() => {
    if (swiperRef.current && gallerySection !== undefined) {
      swiperRef.current.slideTo(0);
    }
  }, [gallerySection]);

  useEffect(() => {
    if (swiperRef.current && galleryIndex !== undefined) {
      swiperRef.current.slideTo(galleryIndex);
    }
  }, [galleryIndex]);

  return (
    <Swiper
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
      }}
      effect={slideEffect}
      spaceBetween={0}
      slidesPerView={1}
      centeredSlides={true}
      onSlideChange={(swiper) =>
        syncMultipleStatesWithSocket({ galleryIndex: swiper.activeIndex })
      }
      fadeEffect={{ crossFade: true }}
      modules={[EffectFade, Navigation, Pagination]}
      speed={800}
      style={{ height: '100%', width: '100%' }}
    >
      {slides.map((content, index) => (
        <SwiperSlide
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <FullScreenImg
            src={content}
            style={{ width: '100%', height: '100%' }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;

const FullScreenImg = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: ${globalStyles.colors.black};
`;

import {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  ReactElement,
} from 'react';
import { createRoot } from 'react-dom/client';
import styled from 'styled-components';
import MAPBOX from 'mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

type TMarker = {
  component: ReactElement;
  x: number;
  y: number;
};

type TLineData = {
  source: string;
  coords: number[][];
  lineWidth: number;
  lineColor: string;
  lineOpacity: number;
};

type TMapProps = {
  token: string;
  styleURL: string;
  centralMarker?: TMarker;
  poiMarkersArr?: TMarker[];
  linesArr?: TLineData[];
  onLoad?: () => void;
  [x: string]: unknown;
};

const Map = forwardRef(function Map(props: TMapProps, ref) {
  const { token, styleURL, centralMarker, poiMarkersArr, linesArr, onLoad } =
    props;
  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const mapRenderRef = useRef<MAPBOX.Map | null>(null);
  const lineLayersRef = useRef<object>({});

  const initialValues = {
    x: -79.51,
    y: 43.72,
    zoom: 8.06,
  };

  useImperativeHandle(ref, () => {
    return {
      getRef(): MAPBOX.Map | null {
        return mapRenderRef.current;
      },
      getLineLayers(): object {
        return lineLayersRef.current;
      },
      updateLineLayerOpacity(layerID, opacity) {
        const map = mapRenderRef.current!;
        if (map) {
          const layer = map.getLayer(layerID);
          if (layer) {
            map.setPaintProperty(layerID, 'line-opacity', opacity);
          }
        }
      },
      updateLineGradient(layerID, expresion: MAPBOX.Expression) {
        const map = mapRenderRef.current!;
        if (map) {
          const layer = map.getLayer(layerID);
          if (layer) {
            map.setPaintProperty(layerID, 'line-gradient', expresion);
          }
        }
      },
    };
  });

  useEffect(() => {
    if (token && !MAPBOX.accessToken) {
      MAPBOX.accessToken = token;
    }
  }, []);

  useEffect(() => {
    if (mapRenderRef.current) return;
    mapRenderRef.current = new MAPBOX.Map({
      container: mapWrapperRef.current as HTMLDivElement,
      style: styleURL,
      center: [initialValues.x, initialValues.y],
      zoom: initialValues.zoom,
    });

    if (poiMarkersArr) {
      for (let i = 0; i < poiMarkersArr.length; i++) {
        const poiMarkerDiv = document.createElement('div');
        poiMarkerDiv.id = `poi-marker-${i}`;
        // render(poiMarkersArr[i]!.component, poiMarkerDiv);
        poiMarkerDiv.setAttribute(
          'aria-coordinates',
          `${poiMarkersArr[i]!.x}, ${poiMarkersArr[i]!.y}`,
        );
        const renderer = createRoot(poiMarkerDiv);
        renderer.render(poiMarkersArr[i]!.component);
        new MAPBOX.Marker(poiMarkerDiv)
          .setLngLat([poiMarkersArr[i]!.x, poiMarkersArr[i]!.y])
          .addTo(mapRenderRef.current as MAPBOX.Map);
      }
    }

    if (centralMarker) {
      const markerDiv = document.createElement('div');
      markerDiv.id = 'centralMarker';
      markerDiv.setAttribute(
        'aria-coordinates',
        `${centralMarker.x}, ${centralMarker.y}`,
      );
      const renderer = createRoot(markerDiv);
      renderer.render(centralMarker!.component);
      new MAPBOX.Marker(markerDiv)
        .setLngLat([centralMarker.x, centralMarker.y])
        .addTo(mapRenderRef.current as MAPBOX.Map);
    }

    mapRenderRef.current.on('move', () => {});
    mapRenderRef.current.on('load', () => {
      if (linesArr) {
        for (let i = 0; i < linesArr.length; i++) {
          const { source, coords, lineColor, lineWidth, lineOpacity } =
            linesArr[i];
          mapRenderRef.current!.addSource(source, {
            ...lineSourceBase,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore n/a
            data: {
              ...lineSourceBase.data,
              geometry: {
                ...lineSourceBase.data.geometry,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore n/a
                coordinates: coords,
              },
            },
          });
          mapRenderRef.current!.addLayer({
            ...lineLayerBase,
            id: source,
            source: source,
            paint: {
              //@ts-expect-error  - no types  - this still breaks the build !
              'line-width': lineWidth,
              'line-color': lineColor,
              'line-opacity': lineOpacity,
              'line-gradient': ['step', 0, `${lineColor}`, 1, `${lineColor}`],
            },
          });
          if (mapRenderRef.current!.getLayer(source)) {
            lineLayersRef.current![source] =
              mapRenderRef.current!.getLayer(source);
          }
        }
      }
      mapRenderRef.current!.resize();
      onLoad && onLoad();
    });
  });

  useEffect(() => {
    const watermark = document.getElementsByClassName(
      'mapboxgl-control-container',
    )[0] as HTMLElement;
    watermark.style.display = 'none';
  }, []);

  return <MapWrapper ref={mapWrapperRef} {...props} />;
});

export default Map;

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const lineSourceBase = {
  type: 'geojson',
  lineMetrics: true,
  data: {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: null,
    },
  },
};

const lineLayerBase = {
  id: null,
  type: 'line',
  source: null,
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': null,
    'line-width': null,
    'line-opacity': null,
  },
};
